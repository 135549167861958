<template>
  <v-snackbar
    v-model="showSnackbar"
    max-width="100%"
    top
    app
    :timeout="snackbarTimer"
    :color="snackbarColor"
  >
    <div class="d-flex justify-center flex-wrap">
      <v-btn
        v-for="(cta, index) in ctas"
        :key="index"
        style="margin-right: 8px"
        text
        @click="handleClick(cta.routeName)"
      >
        {{ cta.label }}
      </v-btn>
      <v-btn icon large color="primary" @click="$emit('close')">
        <v-icon large>mdi-close</v-icon>
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
import { computed } from '@vue/composition-api';

export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    snackbarColor: {
      type: String,
      default: '#3c9dcd'
    },
    snackbarTimer: {
      type: Number,
      default: 0
    },
    ctas: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  setup(props, ctx) {
    const showSnackbar = computed({
      get: () => props.isShow,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const handleClick = routeName => {
      ctx.root.$router.push({ name: routeName });
    };

    return {
      showSnackbar,
      handleClick
    };
  }
};
</script>

<style lang="scss">
.v-snack:not(.v-snack--absolute) {
  position: relative;
  height: auto;
  margin-bottom: 40px;
  width: 100%;
  @media (min-width: 1100px) {
    width: calc(100% + 130px);
  }
  @media (max-width: 991px) {
    margin-top: 20px;
    position: fixed;
    bottom: 95px;
    top: auto;
  }
}
.v-snack__content {
  padding: 0 30px 0 10px !important;
}
.v-snack {
  &__content {
    .v-btn--icon {
      width: 38px;
      height: 38px;
      position: absolute;
      right: 5px;
      .v-icon {
        font-size: 28px !important;
      }
    }
  }
}
</style>
